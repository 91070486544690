/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';

import { hasGenericChartAxes, t } from '@superset-ui/core';
import { Dataset, isTemporalColumn } from '@superset-ui/chart-controls';
import DateFilterControl from 'src/explore/components/controls/DateFilterControl/DateFilterLabel';
import ControlHeader from 'src/explore/components/ControlHeader';

interface DatePickerInFilterProps {
  columnName: string;
  timeRange?: string;
  datasource: Dataset;
  onChange: (columnName: string, timeRange: string) => void;
}

export const useDatePickerInAdhocFilter = ({
  columnName,
  timeRange,
  datasource,
  onChange,
}: DatePickerInFilterProps): React.ReactElement | undefined => {
  const onTimeRangeChange = (val: string) => onChange(columnName, val);

  return hasGenericChartAxes && isTemporalColumn(columnName, datasource) ? (
    <>
      <ControlHeader label={t('Time Range')} />
      <DateFilterControl
        value={timeRange}
        name="time_range"
        onChange={onTimeRangeChange}
        overlayStyle="Modal"
      />
    </>
  ) : undefined;
};
